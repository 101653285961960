import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/Sidebar"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.edges

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <div className="container-lg">
        <div className="row">
          <div className="col-lg-8">
            <ol style={{ listStyle: `none` }}>
              {posts.map(({ node }) => {
                let featuredImgFluid =
                  node.frontmatter.featured_image.src.childImageSharp.fluid

                const createFullPostMarkup = () => {
                  return { __html: `${node.frontmatter.lead || node.excerpt}` }
                }

                let slug = `/${node.frontmatter.articleslug}`

                if (node.frontmatter.articleslug === "/") {
                  slug = "/"
                }

                return (
                  <li key={node.slug}>
                    <article
                      className="article"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <Link to={slug}>
                        <Img
                          fluid={featuredImgFluid}
                          alt={node.frontmatter.featured_image.alt}
                          className="featured-image"
                        />
                      </Link>
                      <Link to={slug}>
                        <h2>{node.frontmatter.title}</h2>
                      </Link>

                      <div className="article-meta">
                        <div>
                          {/* By <span itemProp="author">{node.frontmatter.author}</span> | */}
                          Published{" "}
                          <span itemProp="datePublished">
                            {node.frontmatter.date}
                          </span>
                        </div>
                      </div>

                      <p
                        className="article-lead"
                        dangerouslySetInnerHTML={createFullPostMarkup()}
                      ></p>
                      <Link to={slug}>View Article</Link>
                    </article>
                    <hr />
                  </li>
                )
              })}
            </ol>
          </div>
          <div className="col-lg-4">
            <Sidebar related={false} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

// class BlogIndex extends React.Component {
//   render() {
//     const { data } = this.props
//     const siteTitle = data.site.siteMetadata.title
//     const posts = data.allMdx.edges

//     return (
//       <Layout location={this.props.location} title={siteTitle}>
//         {posts.map(({ node }) => {
//           const title = node.frontmatter.title || node.slug
//           return (
//             <div key={node.slug}>
//               <h3>
//                 <Link to={node.slug}>{title}</Link>
//               </h3>
//               <small>{node.frontmatter.date}</small>
//               <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
//             </div>
//           )
//         })}
//       </Layout>
//     )
//   }
// }

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            articleslug
            date(formatString: "D MMMM Y")
            description
            categories
            author
            lead
            featured_image {
              src {
                childImageSharp {
                  fluid(maxWidth: 1110, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              credit
            }
          }
          slug
        }
      }
    }
  }
`
